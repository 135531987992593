<template>
  <div>
    <v-container class="container px-0">
      <v-row
        class="title-row"
        :class="`${
          $vuetify.breakpoint.mdAndDown ? 'flex-column flex-nowrap' : ''
        }`"
      >
        <v-col class="d-flex align-center">
          <h4 class="font-weight-light">
            {{ $t("heading.hosting.domains.title") }}
          </h4>
          <active-server-account-selector
            v-if="service && service.server_accounts.length > 1"
            class="ml-4"
            :value.sync="serverAccount"
            :items="service.server_accounts"
          />
        </v-col>

        <v-col
          v-if="serverAccount && !serverAccount.diagnostic_mode"
          v-show="items.length"
          class="page-title__filter-controls justify-end"
          :class="[`${$vuetify.breakpoint.mobile ? 'pb-3' : ''}`]"
        >
          <data-iterator-filter-controls
            :keys="keys"
            :showSearchTerm="true"
            :searchTerm="searchTerm"
            :fullWidthSearch="false"
            :fullWidthLg="false"
            :fullWidthMd="true"
            @update:sortBy="changeSortBy"
            @update:sortDesc="handleSortDescChange"
            @update:searchTerm="handleSearchTermChange"
          ></data-iterator-filter-controls>

          <v-btn
            :large="!$vuetify.breakpoint.smAndDown"
            elevation="0"
            color="primary"
            class="p-2 add-new-button page-title__add-button"
            @click="showAddDomainModal"
          >
            <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
              >$plus
            </v-icon>
            {{ $vuetify.breakpoint.mobile ? "" : $t("button.domain.add") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-3 px-0">
      <v-row>
        <v-col cols="12">
          <div :class="checkedItems.length > 0 ? 'pb-15' : ''">
            <hosting-domains-table
              v-if="serverAccount && !serverAccount.diagnostic_mode"
              :checkedItems="checkedItems"
              :headers="headers"
              :items="items"
              :itemsLoading="itemsLoading"
              :itemsPerPage="itemsPerPage"
              :itemsTotal="itemsTotal"
              :page="page"
              :possibleItemsPerPage="[[8, 16, 24]]"
              :searchTerm="searchTerm"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              @clearFilters="searchTerm = ''"
              @update:check-all="handleSelectAll"
              @update:checked-items="handleItemCheck"
              @update:itemsPerPage="changePerPage"
              @update:page="changePage"
              @update:sortBy="changeSortBy"
              @update:sortDesc="changeSortDesc"
              @action-button-ssl-edit="loadEditSslModal"
              @action-button-edit="showEditModal"
              @action-button-delete="showDeleteModal"
              @action-button-set-primary-domain="showSetAsPrimaryDomainModal"
              :itemClass="itemClass"
              :serverAccount="serverAccount"
              :service="service"
            >
              <template v-slot:no-data>
                <TableFullScreenMessage
                  :title="$t('message.emptyTable.domain.title')"
                  :desc="$t('message.emptyTable.domain.description')"
                >
                  <template v-slot:image>
                    <hosting-domains-illustration />
                  </template>
                  <template v-slot:action>
                    <v-btn
                      :large="!$vuetify.breakpoint.smAndDown"
                      :small="$vuetify.breakpoint.smAndDown"
                      rounded
                      elevation="0"
                      color="primary"
                      class="p-2 add-new-button"
                      @click="showAddDomainModal"
                    >
                      <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
                        >$plus
                      </v-icon>
                      {{
                        $vuetify.breakpoint.mobile
                          ? ""
                          : $t("button.domain.add")
                      }}
                    </v-btn>
                  </template>
                </TableFullScreenMessage>
              </template>
              <template v-slot:type="item">
                <span>{{ item.type_text }}</span>
              </template>
              <template v-slot:updated_at="item">
                <span>{{ item.updated_at_text }}</span>
              </template>
            </hosting-domains-table>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      transition="custom-dialog-transition"
      :persistent="modalOptions.persistent"
      v-model="modalOptions.open"
      ref="dialog"
    >
      <div
        class="card-overlay"
        v-if="!modalOptions.persistent"
        @click="modalOptions.open = !modalOptions.open"
      />
      <div class="card-overlay" v-else @click="$refs.dialog.animateClick()" />
      <basic-modal
        style="width: 560px"
        :modalOptions="modalOptions"
        @modal-close="modalClose"
        :key="modalRender"
        ref="modal"
      >
        <template v-slot:custom="{ item }">
          <div class="mb-4" v-if="item.domain">
            <div
              v-if="item.domain.sslInstalledCert"
              class="d-inline-flex justify-center"
            >
              {{ $t("heading.instance.changeSslModal.serverCertificate") }}:
              <ssl-indicator
                :fetching="false"
                :ssl="
                  item.domain.sslInstalledCert
                    ? {
                        certificate_installed: true,
                        certificate: item.domain.sslInstalledCert,
                      }
                    : {
                        certificate_installed: false,
                        certificate: {},
                      }
                "
                :text="
                  item.domain.sslInstalledCert
                    ? item.domain.sslInstalledCert.self_signed
                      ? $t('general.sslProvider.selfSigned')
                      : item.domain.sslInstalledCert.issuer_name
                    : $t('general.sslProvider.none')
                "
                :textClass="
                  item.domain.sslInstalledCert
                    ? !(
                        item.domain.sslInstalledCert.self_signed ||
                        item.domain.sslInstalledCert.name_match
                      )
                      ? 'success--text'
                      : 'error--text'
                    : 'error-text'
                "
                :isIcon="false"
                class="ms-4 me-1 d-inline-block"
              />
            </div>
            <div
              v-if="item.domain.sslDetectedCert"
              class="d-inline-flex justify-center mb-1"
            >
              {{ $t("heading.instance.changeSslModal.detectedCertificate") }}:
              <ssl-indicator
                :fetching="false"
                :ssl="
                  item.domain.sslDetectedCert
                    ? {
                        certificate_installed: true,
                        certificate: item.domain.sslDetectedCert,
                      }
                    : {
                        certificate_installed: false,
                        certificate: {},
                      }
                "
                :text="
                  item.domain.sslDetectedCert
                    ? item.domain.sslDetectedCert.self_signed
                      ? $t('general.sslProvider.selfSigned')
                      : item.domain.sslDetectedCert.issuer_name
                    : $t('general.sslProvider.none')
                "
                :textClass="
                  item.domain.sslDetectedCert
                    ? !(
                        item.domain.sslDetectedCert.self_signed ||
                        item.domain.sslDetectedCert.name_match
                      )
                      ? 'success--text'
                      : 'error--text'
                    : 'error-text'
                "
                :isIcon="false"
                class="mx-4 d-inline-block"
              />
            </div>
          </div>
        </template>
      </basic-modal>
    </v-dialog>
    <transfer-domain-modal
      :is-open="transferDomainModalOpen"
      :selected-service="service"
      :domain="transferDomain"
      :dns-record="transferDnsRecord"
      @removeModal="transferDomainModalOpen = false"
      @transfer-domain="addDomain"
    />
  </div>
</template>

<script>
import Api from "../../apis/Api";

import BasicModal from "@/components/modal/BasicModal";
import DataIteratorFilterControls from "../../components/dataIterator/DataIteratorFilterControls.vue";
import HostingDomainsTable from "../../components/dataIterator/tables/HostingDomainsTable.vue";
import TableFullScreenMessage from "../../components/dataIterator/tables/TableFullScreenMessage.vue";
import HostingDomainsIllustration from "../../components/illustrations/hosting/hosting-domains-illustration.vue";

import ActionModalMixin from "@/mixins/ActionModalMixin";
import DataIteratorPageMixin from "../../mixins/DataIteratorPageMixin";
import CustomTablePageMixin from "../../mixins/CustomTablePageMixin";
import EditSslMixin from "../../mixins/EditSslMixin";
//import HostingSubpageMixin from "../../mixins/HostingSubpageMixin";

import moment from "moment";
import SslIndicator from "@/components/security/SslIndicator.vue";
import ActiveServerAccountSelector from "@/components/ActiveServerAccountSelector.vue";
import TransferDomainModal from "../../components/modal/TransferDomainModal.vue";
import DomainsActionsMixin from "@/mixins/DomainsActionsMixin";

export default {
  components: {
    TransferDomainModal,
    ActiveServerAccountSelector,
    SslIndicator,
    BasicModal,
    DataIteratorFilterControls,
    HostingDomainsTable,
    TableFullScreenMessage,
    HostingDomainsIllustration,
  },
  mixins: [
    ActionModalMixin,
    DataIteratorPageMixin,
    CustomTablePageMixin,
    EditSslMixin,
    //HostingSubpageMixin,
    DomainsActionsMixin
  ],
  data() {
    return {
      isServerProvided: false,
      items: [],
      headers: [
        {
          text: "domain",
          value: "domain",
          sortable: true,
        },
        {
          text: "ssl",
          value: "ssl_status",
          sortable: true,
        },
        {
          text: "type",
          value: "type",
          sortable: true,
        },
        {
          text: "documentRoot",
          value: "document_root",
          sortable: true,
        },
        {
          text: "actions",
          value: "actions",
        },
      ],
      sortBy: "domain",
      sortDesc: true,
      page: 1,
      itemsPerPage: 8,
      itemsLoading: true,
      searchTerm: "",
      modalRender: 0,
      modalOptions: { open: false },
      highlightItem: {},
      sslOffers: [],
      serverAccount: null,
      supportedDomainTypes: [],

      transferDomainModalOpen: false,
      transferDomain: {},
      transferDnsRecord: {}
    };
  },
  props: {
    service: Object,
  },
  methods: {
    reloadData(sortByNewest = false) {
      if (this.serverAccount.diagnostic_mode) {
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: this.$t("message.hostingAccountInDiagnosticMode"),
        });
        this.itemsLoading = false;
        return;
      }
      this.itemsLoading = true;
      let sortQuery = sortByNewest ? "?sort_by=updated_at" : "";
      Api.cached(0)
        .get(`/server-accounts/${this.serverAccount.id}/domains${sortQuery}`)
        .then((response) => {
          this.items = response.data.data.map((item) => {
            item.nsStatus = "ok";
            if (item.type != "sub" && item.has_dns_zone) {
              Api.dns().verifyDomainResolving(
                item.domain,
                "",
                this.service.dns_settings,
                this.service.dns_server_enabled,
                this.serverAccount.ipv4_addresses,
                this.serverAccount.nameservers
              ).then((status) => {
                item.nsStatus = status ? "ok" : false;
              });
            }
            //
            this.loadDomainDetectedSslStatus(item);
            this.loadDomainInstalledSslStatus(item);
            //
            item.editSslButtonLoading = false;
            item.serverAccountId = this.serverAccount.id;
            //
            item.updated_at_text = item.updated_at
              ? moment(item.updated_at).fromNow()
              : "-";
            item.type_text = item.type;
            switch (item.type) {
              case "main":
                if (this.service.hosting_server_type === 'wp-cloud') {
                  item.type_text = this.$t("heading.hosting.domains.domain.primary");
                } else {
                  item.type_text = this.$t("heading.hosting.domains.domain.main");
                }
                break;
              case "addon":
                item.type_text = this.$t(
                  "heading.hosting.domains.domain.addon"
                );
                break;
              case "sub":
                item.type_text = this.$t("heading.hosting.domains.domain.sub");
                break;
              case "alias":
                item.type_text = this.$t(
                  "heading.hosting.domains.domain.alias"
                );
                break;
            }
            item.manage_ssl = true;
            if (!response.data.meta.actions.includes('ssl_subdomains')) {
              item.manage_ssl = item.type === "addon" || item.type === "main";
            }
            item.actions = response.data.meta.actions;

            return item;
          });
          this.supportedDomainTypes = response.data.meta.domain_types;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.itemsLoading = false;
        });
    },
    loadDomainDetectedSslStatus(item) {
      item.sslStatusLoading = true;
      item.sslDetectedCert = null;
      Api.hosting(this.serverAccount.id)
        .getDetectedCertificate(item.domain)
        .then((cert) => {
          item.sslStatusLoading = false;
          item.sslDetectedCert = cert;
        });
    },
    loadDomainInstalledSslStatus(item) {
      item.sslStatusLoading = true;
      item.sslInstalledCert = null;
      Api.hosting(this.serverAccount.id)
        .getInstalledCertificate(item.domain)
        .then((cert) => {
          item.sslStatusLoading = false;
          item.sslInstalledCert = cert;
        });
    },
    itemClass(item) {
      if (
        this.highlightItem.domain &&
        item.domain == this.highlightItem.domain
      ) {
        return "highlight";
      }
    },
    verifyDomain(formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      let data = { ...formData };
      if (data.type === "sub") {
        data.domain += "." + data.parent_domain;
      }

      Api.get(`/services/${this.service.id}/verify-domain?domain=${data.domain}`)
        .then((response) => {
          if (response.data.verification_required) {
            this.transferDomain = data;
            this.transferDnsRecord = response.data.dns_record;

            this.modalOptions.persistent = false;
            this.modalOptions.open = false;

            this.transferDomainModalOpen = true;
            return;
          }
          this.addDomain(formData);
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        }).finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        })
    },
    changeHeaders() {
      const documentRootHeader = {
        text: "documentRoot",
        value: "document_root",
        sortable: true,
      };

      if (this.service.hosting_server_type === 'wp-cloud') {
        if (this.headers.find(header => header.value === 'document_root')) {
          this.headers = this.headers.filter(
            (header) => header.value !== "document_root"
          );
        }
      } else if (!this.headers.find(header => header.value === 'document_root')){
        this.headers.splice(3, 0, documentRootHeader);
      }
    },
  },
  watch: {
    highlightItem: function (newValue) {
      if (!newValue) return;
      setTimeout(() => {
        this.highlightItem = {};
      }, 1000);
    },
    service: function () {
      this.serverAccount = this.service.server_accounts[0];
    },
    serverAccount: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.reloadData();
        this.changeHeaders()
      }
    },
    "modalOptions.open": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
  computed: {
    itemsTotal: function () {
      return this.items.length;
    },
  },
  mounted: function () {
    if (this.service) {
      this.serverAccount = this.service.server_accounts[0];
      this.changeHeaders();
    }

    this.$on("ssl-certificate-installed", (item) => {
      Api.hosting(this.serverAccount.id).resetCache();
      this.loadDomainInstalledSslStatus(item);
    });
    this.$on("ssl-certificate-ordered", (item) => {
      for (let domain of this.items) {
        if (domain.domain == item.domain) {
          item.ssl_order_status = "pending";
          return;
        }
      }
    });
  },
};
</script>

<style scoped lang="scss">
.title-row {
  @media (min-width: 1401px) {
    height: 70px;
  }
}
</style>
